
function obs_top() {
	const obTarget = document.querySelector('.catch');
	const chgTarget = document.querySelector('.sidenavi');

	const options = {
		root: null,
		rootMargin: '-50%',
		threshold: 0
	}

	const obs = new IntersectionObserver(callback, options);
	obs.observe(obTarget);

	function callback(entries, observer) {
		entries.forEach(entry => {

			if (entry.isIntersecting) {
				chgTarget.classList.add('hide');

			} else {
				chgTarget.classList.remove('hide');

			}
		})
	}
}




function obs_work() {
	const obTarget = document.querySelector('.aboutWork');
	const chgTarget = document.querySelector('.menuWork');
	const chgTarget2 = document.querySelector('.menuWork2');
	const chgTarget3 = document.querySelector('.aboutWork__svgText');
	const options = {
		root: null,
		rootMargin: '-50%',
		threshold: 0
	}

	const obs = new IntersectionObserver(callback, options);
	obs.observe(obTarget);

	function callback(entries, observer) {
		entries.forEach(entry => {

			if (entry.isIntersecting) {
				chgTarget.classList.add('active');
				chgTarget2.classList.add('active');
				chgTarget3.classList.add('active');
			} else {
				chgTarget.classList.remove('active');
				chgTarget2.classList.remove('active');
			}
		})
	}
}

function obs_fellow() {
	const obTarget = document.querySelector('.aboutFellow');
	const chgTarget = document.querySelector('.menuFellow');
	const chgTarget2 = document.querySelector('.menuFellow2');
	const chgTarget3 = document.querySelector('.aboutFellow__svgText');
	const options = {
		root: null,
		rootMargin: '-50%',
		threshold: 0
	}

	const obs = new IntersectionObserver(callback, options);
	obs.observe(obTarget);

	function callback(entries, observer) {
		entries.forEach(entry => {

			if (entry.isIntersecting) {
				chgTarget.classList.add('active');
				chgTarget2.classList.add('active');
				chgTarget3.classList.add('active');
			} else {
				chgTarget.classList.remove('active');
				chgTarget2.classList.remove('active');
			}
		})
	}
}


function obs_atmos() {
	const obTarget = document.querySelector('.atmos');
	const chgTarget = document.querySelector('.menuAtmos');
	const chgTarget2 = document.querySelector('.menuAtmos2');
	const chgTarget3 = document.querySelector('.atmosText__svgText');
	const options = {
		root: null,
		rootMargin: '-50%',
		threshold: 0
	}

	const obs = new IntersectionObserver(callback, options);
	obs.observe(obTarget);

	function callback(entries, observer) {
		entries.forEach(entry => {

			if (entry.isIntersecting) {
				chgTarget.classList.add('active');
				chgTarget2.classList.add('active');
				chgTarget3.classList.add('active');
			} else {
				chgTarget.classList.remove('active');
				chgTarget2.classList.remove('active');
			}
		})
	}
}


function obs_num() {
	const obTarget = document.querySelector('.num');
	const chgTarget = document.querySelector('.menuNum');
	const chgTarget2 = document.querySelector('.menuNum2');
	const chgTarget3 = document.querySelector('.num__svgText');
	const options = {
		root: null,
		rootMargin: '-50%',
		threshold: 0
	}

	const obs = new IntersectionObserver(callback, options);
	obs.observe(obTarget);

	function callback(entries, observer) {
		entries.forEach(entry => {

			if (entry.isIntersecting) {
				chgTarget.classList.add('active');
				chgTarget2.classList.add('active');
				chgTarget3.classList.add('active');
			} else {
				chgTarget.classList.remove('active');
				chgTarget2.classList.remove('active');
			}
		})
	}
}


obs_top();
obs_work();
obs_fellow();
obs_atmos();
obs_num();

